.gridContainer{
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    background-size: cover;
    height: 90vh;
    width: 100%;
    position: relative;
}
.headLine{
    margin-bottom: 30vh;
    font-size: max(min(7vw,80px),14px);
    font-size: 30;
    color: white;
    font-family: serif;
}

.opac{
    height: 100%;
    width: 100%;
    position: absolute;
    
}