/* Footer Styles */
.footer {
    background-color: #2D4059;
    color: #FFFFFF;
    padding: 20px;
    display: grid;
    grid-template-columns: 25% 15% 15% 15% 15%;
    gap: 2%;
}

.footer a {
    color: #FFFFFF;
    text-decoration: none;
}



.footer p {
    margin: 0;
}

ul{
    font-size: max(min(1.7vw,21px),14px);
    font-weight: bold;
    margin: 5vh auto;
    padding-left: 0;
}

li{
    list-style: none outside;
    font-size: max(min(1.5vw,17px),12px);
    font-weight: lighter;
}
a:hover{
    text-decoration: underline;
    color: #EA5455 ;
    cursor: pointer;
}



@media(max-width: 600px){
    .footer{
        grid-template-columns: 1fr 1fr;
    }


}